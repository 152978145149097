<script>
import Layout from '@layouts/main'
import Breadcrumb from '@/src/components/Breadcrumb.vue'
import PageHeader from '@/src/components/PageHeader.vue'
import DataModeVuetablePagination from '@components/DataModeVuetablePagination'
import { authComputed } from '@state/helpers'

import { Map, Marker, gmapApi } from 'gmap-vue'
import { faHome, faMapMarkerAlt } from '@fortawesome/free-solid-svg-icons'

import DailyUserRouteService from '@src/services/dashboards/DailyUserRouteService'

import multiSelectWithService from '@src/components/multiSelectWithService'
import filterVuetable from '@src/mixins/filterVuetable'
import vuetableFormatter from '@src/mixins/vuetableFormatters'

import DirectionsRenderer from '@src/components/map/directionsRenderer'
import DatePicker from '@/src/components/DatePicker'
import FilterButton from '@/src/components/FilterButton'
import trackMapModal from '@src/router/views/dashboards/trackMap/trackMapModal'
// import cloneObject from '@utils/clone-object'
import moment from 'moment';
import { orderBy, round } from 'lodash'
import exportKmModal from '@/src/router/views/dashboards/workSchedulesMap/exportKmModal';

const i18nKey = 'DASHBOARDS'
const i18nCommon = 'COMMON'

export default {
    components: {
        Layout,
        Breadcrumb,
        PageHeader,
        DataModeVuetablePagination,
        'gmap-map': Map,
        GmapMarker: Marker,
        DatePicker,
        multiSelectWithService,
        DirectionsRenderer,
        FilterButton,
        trackMapModal,
        exportKmModal,
    },
    mixins: [filterVuetable, vuetableFormatter],
    data() {
        return {
            i18nKey,
            i18nCommon,
            loading: true,
            center: {
                lat: -25.4269067,
                lng: -49.2427093,
            },
            posMarker: {
                path: faMapMarkerAlt.icon[4],
                scale: 0.07,
                fillColor: '#808895',
                fillOpacity: 1,
                strokeWeight: 0,
                rotation: 0,
            },
            posVisitedMarker: {
                path: faMapMarkerAlt.icon[4],
                scale: 0.07,
                fillColor: '#2E7FEB',
                fillOpacity: 1,
                strokeWeight: 0,
                rotation: 0,
            },
            // checkInMarker: {
            //     path: faSignInAlt.icon[4],
            //     scale: 0.07,
            //     fillColor: '#89E09C',
            //     fillOpacity: 1,
            //     strokeWeight: 0,
            //     rotation: 0,
            // },
            homeMarker: {
                path: faHome.icon[4],
                scale: 0.07,
                fillColor: '#ed6112',
                fillOpacity: 1,
                strokeWeight: 0,
                rotation: 0,
            },
            usersParameters: {
                per_page: 1000,
                page: 1,
                orderBy: 'name',
                sortedBy: 'asc',
                active: true,
            },
            filters: {
                date: new Date(),
                user: null,
            },
            user: null,
            workSchedules: [],
            pointOfSalesWithLocation: [],
            pointOfSalesWithoutLocation: [],
            posChecks: [],
            posChecksRoute: [],
            additionalParameters: {},
            userPosition: null,
            totalPredicted: {
                distance: 0,
                duration: 0,
            },
            totalPerformed: {
                distance: 0,
                duration: 0,
            },
            workSchedulesFields: [
                {
                    name: 'pointOfSale.name',
                    title: this.getI18n('POS', 'TITLES.pos'),
                    sortField: 'pointOfSale.name',
                },
                {
                    name: 'pointOfSale.long_name',
                    title: this.getI18n(i18nCommon, 'address'),
                    sortField: 'pointOfSale.long_name',
                },
                {
                    name: 'distance_from_last_point',
                    title: this.getI18n(i18nKey, 'TITLES.distance_from_last_point'),
                    sortField: 'distance_from_last_point',
                },
                {
                    name: 'accomplished',
                    title: this.getI18n(i18nKey, 'TITLES.accomplished'),
                    formatter: this.booleanIcons,
                    sortField: 'accomplished',
                },
                {
                    name: 'photo_checkin',
                    title: this.getI18n(i18nKey, 'TITLES.photo_checkin'),
                },
                // {
                //     name: 'pointOfSale.long_name',
                //     title: this.getI18nModified({
                //         prefix: i18nCommon,
                //         suffix: 'detail',
                //         modifier: 2,
                //     }),
                // },
            ]
        }
    },
    computed: {
        items() {
            return [
                {
                    text: 'Home',
                    href: '/',
                },
                {
                    text: this.getI18nModified({
                        prefix: i18nKey,
                        suffix: 'TITLES.dashboards',
                        modifier: 2,
                    }),
                    active: true,
                },
                {
                    text: this.i18nDailyRoute,
                    active: true,
                },
            ]
        },
        ...authComputed,
        google: gmapApi,
        i18nDailyRoute() {
            return this.getI18n(i18nCommon, 'daily_route')
        },
        i18nDate() {
            return this.getI18n(i18nCommon, 'date')
        },
        i18nUser() {
            return this.getI18n('USERS', 'TITLES.user')
        },
        i18nMinutes() {
            return this.$options.filters.firstToLower(
                this.getI18nModified({
                    prefix: i18nCommon,
                    suffix: 'minutes',
                    modifier: 2,
                })
            )
        },
    },
    async mounted() {
        this.filters.user = this.userLoggedIn
        this.filter()
    },
    methods: {
        refreshTable() {
        },
        showExportKmModal() {
            this.$refs.exportKmModal.showModal()
        },
        createParameters() {
            this.userPosition = this.filters.user ?
            {
                lat: this.filters.user.lat,
                lng: this.filters.user.long,
            } : null

            return this.formatParameters({
                user_id: this.filters.user.id,
                date: this.dateToValue(this.filters.date),
            })
        },
        panTo(lat, lng) {
            this.$refs.gmap.$mapPromise.then((map) => {
                var latLng = new this.google.maps.LatLng(lat, lng)
                map.panTo(latLng)
            })
        },
        mapBounds() {
            this.$refs.gmap.$mapPromise.then((map) => {
                if (this.posChecks.length > 0 || this.pointOfSalesWithLocation.length > 0) {
                    const bounds = new this.google.maps.LatLngBounds()
                    if (this.posChecks.length > 0) {
                        for (let i = 0; i < this.posChecks.length; i++) {
                            bounds.extend(
                                new this.google.maps.LatLng(
                                    this.posChecks[i].position.lat,
                                    this.posChecks[i].position.lng
                                )
                            )
                        }
                    }
                    if (this.pointOfSalesWithLocation.length > 0) {
                        for (let i = 0; i < this.pointOfSalesWithLocation.length; i++) {
                            bounds.extend(
                                new this.google.maps.LatLng(
                                    this.pointOfSalesWithLocation[i].position.lat,
                                    this.pointOfSalesWithLocation[i].position.lng
                                )
                            )
                        }
                    }

                    map.fitBounds(bounds)
                }
            })
        },
        async filter() {
            this.additionalParameters = this.createParameters()
            this.user = this.filters.user
            await this.getDailyRoute()
        },
        async getDailyRoute() {
            this.loading = true
            const response = await DailyUserRouteService.fetchAll(this.additionalParameters)
                .then((response) => {
                    const remoteWorkSchedules = response.data.data.work_schedules.filter((workSchedule) => workSchedule.pointOfSale.location[0] !== null && workSchedule.pointOfSale.location[1] !== null);
                    this.pointOfSalesWithoutLocation = response.data.data.work_schedules.filter((workSchedule) => workSchedule.pointOfSale.location[0] === null || workSchedule.pointOfSale.location[1] === null);

                    this.pointOfSalesWithLocation = response.data.data.pos_with_location.map(
                        (pos) => {
                            return this.mapLocation(pos, 'location')
                        }
                    )

                    this.posChecks = response.data.data.pos_checks.map(
                        (posCheck) => {
                            return this.mapLocation(posCheck, 'location_in')
                        }
                    )

                    console.log(remoteWorkSchedules);

                    const workSchedules = []
                    for (var y = 0; y < remoteWorkSchedules.length; y++) {
                        const posCheck = this.posChecks.find((posCheck) => posCheck.point_of_sale_id === remoteWorkSchedules[y].point_of_sale_id)

                        if (workSchedules.find((workSchedule) => workSchedule.point_of_sale_id === remoteWorkSchedules[y].point_of_sale_id)) {
                            continue
                        }

                        workSchedules.push({
                            ...remoteWorkSchedules[y],
                            accomplished: !!(posCheck && posCheck.datetime_out),
                            photo_checkin: posCheck && posCheck.file ? posCheck.file : null,
                            datetime_in: posCheck && posCheck.datetime_in ? moment(posCheck.datetime_in, 'YYYY-MM-DD HH:mm:ss').toDate() : null,
                            distance_from_last_point: 0,
                        })
                    }
                    this.workSchedules = orderBy(workSchedules, ['datetime_in'], ['asc'])


                    this.posChecksRoute = []

                    if (this.workSchedules.length > 0) {
                        if (this.userPosition && this.userPosition.lat && this.userPosition.lng) {
                            const userHouse = {
                                pointOfSale: {
                                    name: 'CASA',
                                    location: [this.userPosition.lat, this.userPosition.lng]
                                },
                                accomplished: false,
                                photo_checkin: false,
                                datetime_in: null,
                                distance_from_last_point: 0,
                            };
                            this.workSchedules.unshift({
                                id: -1,
                                ...userHouse
                            })
                            this.workSchedules.push({
                                id: 0,
                                ...userHouse
                            })
                        }

                        for (let i = 0; i < this.workSchedules.length; i++) {
                            const posOrigin = this.workSchedules[i]
                            const posDestination = this.workSchedules[i +1]
                            if (posDestination && posOrigin.pointOfSale.location && posDestination.pointOfSale.location) {
                                this.posChecksRoute.push({
                                    id: posOrigin.id,
                                    origin: this.latLngFromArray(posOrigin.pointOfSale.location),
                                    destination: this.latLngFromArray(posDestination.pointOfSale.location),
                                })
                            }
                        }
                    }

                    this.getGmapRoute(false)
                    this.getGmapRoute(true)

                    this.$nextTick(() => {
                        this.$refs.relationVuetable.refresh()
                    })

                    this.loading = false
                    return response
                })
                .catch((err) => {
                    this.loading = false
                    return err
                })

            if (response.status.toString().charAt(0) === '2') {
                this.mapBounds()
            }
        },
        mapLocation(posCheck, field) {
            if (!posCheck[field]) {
                return null
            }
            posCheck.position = this.latLngFromArray(posCheck[field])
            return posCheck
        },
        latLngFromArray(position) {
            return {
                lat: position[0],
                lng: position[1],
            }
        },
        toggleInfoWindow(marker) {
            const filter = {
                userId: this.filters.user.id,
                dateTimeIn: {
                    start: this.dateToValue(this.filters.date),
                    end: this.dateToValue(this.filters.date),
                },
                pointOfSaleId: marker.id,
            }
            this.$nextTick(() => this.$refs.trackModal.showModal(filter))
        },
        getGmapRoute(predictedRoute) {
            if (this.posChecksRoute.length <= 0) {
                return
            }

            const waypoints = []
            for (let i = 1; i < this.posChecksRoute.length; i++) {
                if (this.posChecksRoute[i]) {
                    waypoints.push({
                        location: this.posChecksRoute[i].origin,
                        stopover: true,
                    })
                }
            }

            if (predictedRoute) {
                this.totalPredicted = {
                    distance: 0,
                    duration: 0,
                }
            }
            else {
                this.totalPerformed = {
                    distance: 0,
                    duration: 0,
                }
            }

            let routes = []
            const service = new this.google.maps.DirectionsService();
            service.route({
                origin: this.posChecksRoute[0].origin,
                waypoints,
                destination: this.posChecksRoute[this.posChecksRoute.length - 1].destination,
                provideRouteAlternatives: false,
                optimizeWaypoints: predictedRoute,
                travelMode: 'DRIVING',
            }, (response, status) => {
                if (status === 'OK') {

                    if (response.routes && response.routes.length > 0) {
                        if (response.routes[0].legs && response.routes[0].legs.length > 0) {
                            routes = response.routes[0].legs;
                            for (let y = 0; y < this.workSchedules.length; y++) {
                                let waypointIndex = y - 1;
                                let posRoute = routes[waypointIndex];

                                if (predictedRoute) {
                                    this.totalPredicted.distance += posRoute?.distance.value ?? 0;
                                    this.totalPredicted.duration += posRoute?.duration.value ?? 0
                                }

                                if (!predictedRoute) {
                                    if (this.workSchedules[y].accomplished || this.workSchedules[y].id === 0) {
                                        this.totalPerformed.distance += posRoute?.distance.value ?? 0;
                                        this.totalPerformed.duration += posRoute?.duration.value ?? 0
                                    }
                                    this.workSchedules[y].distance_from_last_point = posRoute?.distance.value ?? 0;
                                }
                            }
                        }
                    }
                }
            });
        },
        lodashRound(value, precision) {
            return round(value, precision)
        },
    },
}
</script>

<template>
    <Layout>
        <div class="col-12">
            <Breadcrumb :items="items" />
            <div class="row mt-3 mb-3">
                <div class="col-sm-8">
                    <b-row>
                        <b-col md="5">
                            <b-form-group
                                :label="i18nDate"
                                class="required label-pdv"
                                label-for="filter-date"
                            >
                                <date-picker
                                    :value.sync="filters.date"
                                    :max-date="new Date()"
                                    :disable-clean-button="true"
                                />
                            </b-form-group>
                        </b-col>
                        <b-col md="6">
                            <b-form-group
                                :label="i18nUser"
                                class="label-pdv"
                                label-for="filter-user"
                            >
                                <multiSelectWithService
                                    :id="'filter-user'"
                                    ref="userMultiselect"
                                    v-model="filters.user"
                                    :service="'users'"
                                    :searchable="true"
                                    :multiple="false"
                                    :parameters="usersParameters"
                                />
                            </b-form-group>
                        </b-col>
                    </b-row>
                </div>
                <div class="col-sm-4 align-self-center">
                    <div class="d-flex justify-content-end">
                        <button
                            type="button"
                            class="btn btn-pdv btn-pdv-blue col-md-6 "
                            href="javascript: void(0);"
                            :disabled="loading"
                            @click="showExportKmModal">
                            <i class="fe-download mr-1"></i>
                            Gerar Relatório de KM
                        </button>
                        <filter-button class="col-md-6" @onClick="filter" />
                    </div>
                </div>
            </div>
        </div>
        <b-row>
            <gmap-map
                ref="gmap"
                :center="center"
                :zoom="4"
                :options="{
                    zoomControl: true,
                    mapTypeControl: false,
                    scaleControl: false,
                    streetViewControl: false,
                    rotateControl: false,
                    fullscreenControl: true,
                    disableDefaultUi: false,
                    maxZoom: 18,
                }"
                style="width: 100%; height: 60vh"
            >
                <GmapMarker
                    v-if="userPosition && userPosition.lat && userPosition.lng"
                    :position="userPosition"
                    :icon="homeMarker"
                />
                <!-- <GmapMarker
                    v-for="posCheck in posChecks"
                    :key="posCheck.id + '_check_in'"
                    :position="posCheck.position"
                    :clickable="true"
                    :icon="checkInMarker"
                    :options="{ info: posCheck }"
                    @click="toggleInfoWindow(posCheck)"
                /> -->
                <GmapMarker
                    v-for="pos in pointOfSalesWithLocation"
                    :key="pos.id + '_pos'"
                    :position="pos.position"
                    :clickable="pos.point_of_sales_checks_count > 0"
                    :icon=" pos.point_of_sales_checks_count > 0 ? posVisitedMarker : posMarker"
                    :options="{ info: pos }"
                    @click="toggleInfoWindow(pos)"
                />
                <DirectionsRenderer
                    v-for="route in posChecksRoute"
                    :key="route.id + '_direction'"
                    travel-mode="DRIVING"
                    :origin="route.origin"
                    :destination="route.destination"
                />
            </gmap-map>
        </b-row>

        <div v-if="user" class="card card-pdv mt-3">
            <PageHeader :title="user ? user.name : ''" />
            <div v-if="loading" class="card-body">
                <b-row class="ml-1">
                    <b-skeleton width="100%"></b-skeleton>
                </b-row>
                <b-skeleton-table
                :rows="5"
                :columns="workSchedulesFields.length"
                :table-props="{ hover: true, stickyHeader: true }"
                />
            </div>
            <div v-else class="card-body">
                <b-row class="ml-1">
                    {{ getI18n(i18nKey, 'TITLES.total_distance_predicted') }}:
                    {{ lodashRound(totalPredicted.distance / 1000, 3).toLocaleString('pt-BR') }} km ({{ `${Math.round(totalPredicted.duration / 60)} ${i18nMinutes}` }})
                    | {{ getI18n(i18nKey, 'TITLES.total_distance_performed') }}:
                    {{ lodashRound(totalPerformed.distance / 1000, 3).toLocaleString('pt-BR') }} km ({{ `${Math.round(totalPerformed.duration / 60)} ${i18nMinutes}` }})
                </b-row>

                <b-alert
                    v-if="pointOfSalesWithoutLocation.length > 0"
                    show
                    variant="warning"
                    class="mt-3">
                    <i class="fe-alert-triangle mr-1"></i>
                    Existem {{ pointOfSalesWithoutLocation.length }} PDV(s) sem localização cadastrada
                </b-alert>

                <data-mode-vuetable-pagination
                    ref="relationVuetable"
                    v-model="workSchedules"
                    :fields="workSchedulesFields"
                    :per-page="5"
                >
                    <template slot="distance_from_last_point" slot-scope="props">
                        {{ lodashRound(props.rowData.distance_from_last_point / 1000, 3).toLocaleString('pt-BR') }} km
                    </template>
                    <template slot="photo_checkin" slot-scope="props">
                        <a v-if="props.rowData.photo_checkin && props.rowData.photo_checkin.url"
                            :href="props.rowData.photo_checkin.url"
                            target="_blank">
                            <i class="fe-camera ml-1"/>
                        </a>
                        <i v-else class="fe-camera-off ml-1" />
                    </template>
                </data-mode-vuetable-pagination>

                <div v-if="pointOfSalesWithoutLocation.length > 0" class="mt-4">
                    <h5>PDVs sem localização cadastrada</h5>
                    <div class="table-responsive">
                        <table class="table table-centered table-hover mb-0">
                            <thead>
                                <tr>
                                    <th>Nome do PDV</th>
                                    <th>Endereço</th>
                                </tr>
                            </thead>
                            <tbody>
                                <tr v-for="pos in pointOfSalesWithoutLocation" :key="pos.id">
                                    <td>{{ pos.pointOfSale.name }}</td>
                                    <td>{{ pos.pointOfSale.long_name }}</td>
                                </tr>
                            </tbody>
                        </table>
                    </div>
                </div>
            </div>
        </div>
        <trackMapModal ref="trackModal" />
        <export-km-modal ref="exportKmModal" />
    </Layout>
</template>
